import React from "react"
import { Box, Flex, IconButton } from "@chakra-ui/react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import { HandleTrackingClickType } from "@app/hoc/Section"
import { Icon } from "@app/components/Icon"
import FeaturedProductsItem from "@app/components/Sections/FeaturedProducts/FeaturedProductsItem"

import type { NormalisedProduct } from "@root/types/custom-types/Product/Product"

SwiperCore.use([Navigation])

type Props = {
  id: string
  items: NormalisedProduct[] | undefined
  handleTrackingClick: HandleTrackingClickType
}

const FeaturedProductsCarousel: React.FC<Props> = ({ id, items, handleTrackingClick }) => {
  if (!items?.length) return null

  const navButtonStyles = {
    d: "inline-flex",
    w: "10",
    h: "10",
    borderRadius: "full",
    bg: "background.white",
    boxShadow: "0px 2px 3px rgba(58, 21, 10, 0.1)",
    _focus: { boxShadow: "0px 2px 3px rgba(58, 21, 10, 0.1)" },
  }

  const prevId = `o-${id}-swiper-arrow--prev`
  const nextId = `o-${id}-swiper-arrow--next`

  return (
    <Box pos="relative" sx={{ ".swiper": { pt: 4, alignItems: "stretch" } }}>
      <Swiper
        lazy
        loop
        slidesPerView={4}
        spaceBetween={16}
        navigation={{ prevEl: `#${prevId}`, nextEl: `#${nextId}` }}
        breakpoints={{ 1024: { spaceBetween: 32 } }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <FeaturedProductsItem
              item={item}
              itemPosition={index}
              itemListName="Featured Products Carousel"
              handleTrackingClick={handleTrackingClick}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Flex
        pos="absolute"
        top="calc(50% - 85px)"
        left="50%"
        transform="translate(-50%, -50%)"
        w={{ base: "calc(100% + 40px)" }}
        zIndex="overlay"
        justifyContent="space-between"
        pointerEvents="none"
      >
        <IconButton
          variant="unstyled"
          aria-label="Previous"
          icon={<Icon name="arrows/chevron-left" width="24" height="24" />}
          id={prevId}
          {...navButtonStyles}
          pointerEvents="initial"
        />
        <IconButton
          variant="unstyled"
          aria-label="Next"
          icon={<Icon name="arrows/chevron-right" width="24" height="24" />}
          id={nextId}
          {...navButtonStyles}
          pointerEvents="initial"
        />
      </Flex>
    </Box>
  )
}
export default React.memo(FeaturedProductsCarousel)
