import React, { useMemo } from "react"
import { As, Box, Container, Flex, Heading, Link, Show, Text } from "@chakra-ui/react"

import { useShopify } from "@app/hooks/useShopify"
import { AnalyticProps, withSection } from "@app/hoc/Section"
import ButtonWithIcon from "@app/components/Input/ButtonWithIcon"
import FeaturedProductsGrid from "@app/components/Sections/FeaturedProducts/FeaturedProductsGrid"
import FeaturedProductsCarousel from "@app/components/Sections/FeaturedProducts/FeaturedProductsCarousel"

type Props = GatsbyTypes.SanitySectionFeaturedProducts & AnalyticProps

const FeaturedProducts: React.FC<Props> = ({ title, description, link, products, innerRef, handleTrackingClick, tag }) => {
  const { productNormaliser } = useShopify()
  const id = Date.now().toString()

  const items = useMemo(
    () =>
      products?.map(p => {
        const shopifyData = p?.shopify?.raw ? JSON.parse(p.shopify.raw) : null
        if (shopifyData) return productNormaliser(shopifyData)

        return productNormaliser(p)
      }),
    [productNormaliser, products]
  )

  return (
    <Box as="section">
      <Container
        ref={innerRef}
        maxW="container.4xl"
        py={{ base: "8", md: "12", lg: "18" }}
        px={{ base: "4", md: "8", lg: "min(5.55%, 5rem)" }}
        bg="background.white"
      >
        <Box mb={{ base: "7", lg: "8" }} textAlign="center">
          {title && (
            <Heading as={tag as As} size="h3">
              {title}
            </Heading>
          )}

          {description && (
            <Text size="paragraphLarge" mt={{ base: "2", lg: "3" }}>
              {description}
            </Text>
          )}
        </Box>

        <Show below="lg">
          <FeaturedProductsGrid items={items} handleTrackingClick={handleTrackingClick} />
        </Show>
        <Show above="lg">
          <FeaturedProductsCarousel id={id} items={items} handleTrackingClick={handleTrackingClick} />
        </Show>

        {link && (
          <Flex mt={{ base: "7", lg: "8" }} justifyContent="center">
            <ButtonWithIcon
              as={Link}
              href={link.link}
              isExternal={link.external}
              variant="solidSecondary"
              iconName="arrows/chevron-right"
              w="full"
              maxW={{ base: "container.sm", lg: "76" }}
            >
              {link.title}
            </ButtonWithIcon>
          </Flex>
        )}
      </Container>
    </Box>
  )
}

export default React.memo(withSection(FeaturedProducts))
